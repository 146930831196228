import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
  constructor(
    private title: Title,
    private metaService: Meta
  ) {}

  public setTitle(title: string) {
    this.title.setTitle(title);
    this.metaService.addTags([
      { name: 'twitter:title', content: title },
      { property: 'og:title', content: title },
      { property: 'og:description', content: title },
    ]);
  }
}
